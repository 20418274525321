/*
 * @Descripttion:
 * @Author: yika
 * @Date: 2022-11-28 21:13:21
 * @LastEditors: xiang.gao xiang.gao@travel-easy.com
 * @LastEditTime: 2022-12-22 14:35:59
 */
export enum InspectionStatus {
  Pending = 10,
  Inspection = 20,
  Inspected = 30,
  Stocked = 40,
}

export enum InspectionStage {
  WaitingInspection = 10,
  Checked = 20,
  FirstTimeInspection = 50,
  SecondTimeInspection = 60,
  WaitingApprove = 70,
  InspectionFinished = 80,
}

export enum InspectionResult {
  Passed = 10,
  DefectedArticle = 20,
  RetourToMerchant = 30,
  InternalLoss = 40,
  CreatedBackOrder = 50,
}

export enum InspectionReason {
  IncorrectArticle = 10,
  DamagedArticlePackaging = 20,
  SecondInspectionRequired = 30,
}

export enum InspectionSecondReason {
  Lightly = 10,
  Moderate = 20,
  Obviously = 30,
}
