/*
 * @Descripttion:
 * @Author: yika
 * @Date: 2022-11-28 21:13:05
 * @LastEditors: xiang.gao xiang.gao@travel-easy.com
 * @LastEditTime: 2023-10-19 14:12:56
 */
import { computed } from "vue";
import {
  BadgeStyle,
  BgStyle,
  CommonLanguageType,
  SvgStyle,
} from "../type/common";
import {
  InspectionSecondReason,
  InspectionResult,
  InspectionStatus,
} from "../type/inspection";
import {
  PackageGenerationGuid,
  packageGenerationGuid,
} from "../interface/inspection";

/**
 * @description: 获取订单状态（新）
 * @return {*}
 */
export const getInspectionStatusMap = (
  status: number
): BadgeStyle | undefined => {
  const result = new Map([
    [InspectionStatus.Pending, BadgeStyle.LightPrimary],
    [InspectionStatus.Inspection, BadgeStyle.LightInfo],
    [InspectionStatus.Inspected, BadgeStyle.LightPrimary],
    [InspectionStatus.Stocked, BadgeStyle.LightSuccess],
  ]);
  return result.get(status);
};

/**
 * @description: 获取Result描述
 * @return {*}
 */
export const getInspectionResultDescriptionMap = (
  status: number,
  language: CommonLanguageType
): string | undefined => {
  const result = new Map([
    [InspectionResult.Passed, language("inspection.passedDescription")],
    [
      InspectionResult.DefectedArticle,
      language("inspection.defectedArticleDescription"),
    ],
    [
      InspectionResult.RetourToMerchant,
      language("inspection.retourToMerchantDescription"),
    ],
    [
      InspectionResult.InternalLoss,
      language("inspection.internalLossDescription"),
    ],
    [
      InspectionResult.CreatedBackOrder,
      language("inspection.createdBackOrderDescription"),
    ],
  ]);
  return result.get(status) || "";
};

/**
 * @description: 获取Result对应svg
 * @return {*}
 */
export const getInspectionResultSvgMap = (
  status: number
): string | undefined => {
  const result = new Map([
    [InspectionResult.Passed, "media/icons/duotune/arrows/arr084.svg"],
    [
      InspectionResult.DefectedArticle,
      "media/icons/duotune/general/gen063.svg",
    ],
    [
      InspectionResult.RetourToMerchant,
      "media/icons/duotune/arrows/arr059.svg",
    ],
    [InspectionResult.InternalLoss, "media/icons/duotune/ecommerce/ecm012.svg"],
    [
      InspectionResult.CreatedBackOrder,
      "media/icons/duotune/arrows/arr059.svg",
    ],
  ]);
  return result.get(status) || "";
};

/**
 * @description: 获取Result对应svg颜色
 * @return {*}
 */
export const getInspectionResultSvgStyleMap = (
  status: number
): SvgStyle | undefined => {
  const result = new Map([
    [InspectionResult.Passed, SvgStyle.Success],
    [InspectionResult.DefectedArticle, SvgStyle.Warning],
    [InspectionResult.RetourToMerchant, SvgStyle.Info],
    [InspectionResult.InternalLoss, SvgStyle.Warning],
    [InspectionResult.CreatedBackOrder, SvgStyle.Dark],
  ]);
  return result.get(status);
};

/**
 * @description: 获取Result对应背景颜色
 * @return {*}
 */
export const getInspectionResultBgStyleMap = (
  status: number
): BgStyle | undefined => {
  const result = new Map([
    [InspectionResult.Passed, BgStyle.LightSuccess],
    [InspectionResult.DefectedArticle, BgStyle.LightWarning],
    [InspectionResult.RetourToMerchant, BgStyle.LightInfo],
    [InspectionResult.InternalLoss, BgStyle.LightWarning],
    [InspectionResult.CreatedBackOrder, BgStyle.Light],
  ]);
  return result.get(status);
};

/**
 * @description: 获取Reason标题
 * @return {*}
 */
export const getInspectionSecondReasonTitleMap = (
  status: number,
  language: CommonLanguageType
): string | undefined => {
  const result = new Map([
    [InspectionSecondReason.Lightly, language("inspection.lightlyTitle")],
    [InspectionSecondReason.Moderate, language("inspection.moderateTitle")],
    [InspectionSecondReason.Obviously, language("inspection.obviouslyTitle")],
  ]);
  return result.get(status) || "";
};

/**
 * @description: 获取Reason描述
 * @return {*}
 */
export const getInspectionSecondReasonDescriptionMap = (
  status: number,
  language: CommonLanguageType
): string | undefined => {
  const result = new Map([
    [InspectionSecondReason.Lightly, language("inspection.lightlyDescription")],
    [
      InspectionSecondReason.Moderate,
      language("inspection.moderateDescription"),
    ],
    [
      InspectionSecondReason.Obviously,
      language("inspection.obviouslyDescription"),
    ],
  ]);
  return result.get(status) || "";
};

/**
 * @description: 获取Result对应svg
 * @return {*}
 */
export const getInspectionSecondReasonSvgMap = (
  status: number
): string | undefined => {
  const result = new Map([
    [
      InspectionSecondReason.Lightly,
      "media/icons/duotune/ecommerce/ecm013.svg",
    ],
    [
      InspectionSecondReason.Moderate,
      "media/icons/duotune/ecommerce/ecm014.svg",
    ],
    [
      InspectionSecondReason.Obviously,
      "media/icons/duotune/ecommerce/ecm015.svg",
    ],
  ]);
  return result.get(status) || "";
};

/**
 * @description: 获取Result对应svg颜色
 * @return {*}
 */
export const getInspectionSecondReasonSvgStyleMap = (
  status: number
): SvgStyle | undefined => {
  const result = new Map([
    [InspectionSecondReason.Lightly, SvgStyle.Success],
    [InspectionSecondReason.Moderate, SvgStyle.Warning],
    [InspectionSecondReason.Obviously, SvgStyle.Danger],
  ]);
  return result.get(status);
};

/**
 * @description: 获取Result对应背景颜色
 * @return {*}
 */
export const getInspectionSecondReasonBgStyleMap = (
  status: number
): BgStyle | undefined => {
  const result = new Map([
    [InspectionSecondReason.Lightly, BgStyle.LightSuccess],
    [InspectionSecondReason.Moderate, BgStyle.LightWarning],
    [InspectionSecondReason.Obviously, BgStyle.LightDanger],
  ]);
  return result.get(status);
};

export const setPackageStr = (val: string) => {
  const year = val.substring(0, 2),
    endCode = val.substring(2, val.length);
  return year + "-" + endCode;
};

export const setProductInfo = (data: string) => {
  const arr = data.split("-");
  if (arr.length === 3) {
    return {
      position: arr[1],
      identification_code: arr[2],
      package_number: setPackageStr(arr[0]),
      package_barcode: arr[0],
      product_barcode: data,
    } as PackageGenerationGuid;
  } else {
    return Object.assign({}, packageGenerationGuid);
  }
};

export const checkPackageNo = computed(() => {
  return (value) => {
    let flag = false;
    if (typeof value === "string") {
      if (value.length === 6 && String(Number(value)).length === 6) {
        flag = true;
      }
    }
    return flag;
  };
});

export const checkProductNo = computed(() => {
  return (value) => {
    let flag = false;
    if (typeof value === "string") {
      if (value.length === 9 && String(Number(value)).length === 9) {
        flag = true;
      }
    }
    return flag;
  };
});

export const checkProductBarcode = computed(() => {
  return (value) => {
    let flag = false;
    if (typeof value === "string") {
      const arr = value.split("-");
      if (arr.length === 3) {
        const re = /^[0-9]*$/g;
        if (
          checkPackageNo.value(arr[0]) &&
          arr[1].length >= 2 &&
          re.test(arr[1]) &&
          checkProductNo.value(arr[2])
        ) {
          flag = true;
        }
      }
    }
    return flag;
  };
});

export const checkTrackingNo = computed(() => {
  return (value) => {
    let flag = false;
    if (typeof value === "string") {
      const re = /^[0-9a-zA-Z]*$/g;
      if (re.test(value)) {
        flag = true;
      }
    }
    return flag;
  };
});
