
import {
  computed,
  defineComponent,
  getCurrentInstance,
  onMounted,
  ref,
  watch,
} from "vue";
import { useRoute } from "vue-router";
import { hideModal, modalHideListener } from "@/core/helpers/dom";
import mixin from "@/mixins";
import { useI18n } from "vue-i18n";
import _ from "lodash";
import { CommonHtmlType } from "@/core/directive/type/common";
import { ApiInspection } from "@/core/api";
import { modalShowListener, showModal } from "@/core/directive/function/common";
import { PrinterConfigTaskDocumentContent } from "@/core/directive/interface/print";
import JsBarcode from "jsbarcode";
import { User } from "@/store/modules/AuthModule";
import store from "@/store";

export default defineComponent({
  name: "warehouse-management-inspection-print-preview-modal",
  emits: ["update-list", "reset-form"],
  setup(props, { emit }) {
    const { t } = useI18n();
    const {
      showValidateErrorMsg,
      showFormSubmitSuccessMsg,
      showServerErrorMsg,
    } = mixin();
    const currentInstance: any = getCurrentInstance();

    const route = useRoute();
    const loading = ref<boolean>(false);
    const printPreviewRef = ref<CommonHtmlType>(null);
    const formRef = ref<null | HTMLFormElement>(null);

    const userInfo = ref<User>(store.getters.currentUser);

    const formData = ref({
      products: [] as any[],
      templateURL: "",
    });

    const rules = ref({
      //   radio: [
      //     {
      //       required: true,
      //       message: t("common.isRequired"),
      //       trigger: "change",
      //     },
      //   ],
    });

    const options = ref({});

    watch(
      () => store.getters.currentUser,
      (newVal) => {
        userInfo.value = newVal;
      }
    );

    const getContents = computed(() => {
      let contents: PrinterConfigTaskDocumentContent[] = [];
      formData.value.products.map((item) => {
        contents.push({
          templateURL: formData.value.templateURL,
          signature:
            "19d6f7759487e556ddcdd3d499af087080403277b7deed1a951cc3d9a93c42a7e22ccba94ff609976c5d3ceb069b641f541bc9906098438d362cae002dfd823a8654b2b4f655e96317d7f60eef1372bb983a4e3174cc8d321668c49068071eaea873071ed683dd24810e51afc0bc925b7a2445fdbc2034cdffb12cb4719ca6b7",
          data: item,
        });
      });
      return contents;
    });

    const printProduct = () => {
      currentInstance.proxy.emitter.emit("all-print", getContents.value);
    };

    const printLoading = () => {
      loading.value = false;
    };

    const throttlePrintProduct = _.throttle(printProduct, 2000);

    const debouncePrintProductLoading = _.debounce(printLoading, 2000);

    const modalShow = (data) => {
      showModal(printPreviewRef.value);
      getFormInfo(data);
    };

    const getShowInfo = async (params) => {
      const { data } = await ApiInspection.printedSheet({
        warehouse_code: userInfo?.value.wms_default_warehouse,
        ...params,
      });
      if (data.code == 0) {
        formData.value.products = data.data.product;
        formData.value.templateURL = data.data.templateURL;
      } else {
        showServerErrorMsg(data);
      }
    };

    const generateJSBarcodeImg = () => {
      if (formData.value.products.length === 0) return;
      formData.value.products.map((item, index) => {
        JsBarcode("#JsBarcodeArticle" + index, item.guid, {
          format: "CODE128",
          width: 3,
          height: 38,
          textAlign: "left",
          fontSize: 14,
          fontOptions: "500",
          background: "transparent",
        });
        JsBarcode("#JsBarcodeShipment" + index, item.guid, {
          format: "CODE128",
          width: 3,
          height: 38,
          textAlign: "left",
          fontSize: 14,
          fontOptions: "500",
          background: "transparent",
        });
      });
    };

    const getFormInfo = (data) => {
      loading.value = true;
      Promise.all([getShowInfo(data)])
        .then(() => {
          loading.value = false;
          generateJSBarcodeImg();
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const submit = () => {
      if (!formRef.value) {
        return;
      }
      formRef.value.validate((valid: boolean) => {
        if (valid) {
          if (loading.value && formData.value.products.length === 0) return;
          loading.value = true;
          throttlePrintProduct();
          debouncePrintProductLoading();
        } else {
          showValidateErrorMsg();
          return false;
        }
      });
    };

    const handleDiscard = () => {
      hideModal(printPreviewRef.value);
      resetForm();
    };

    const resetForm = () => {
      formRef.value?.resetFields();
      emit("reset-form");
    };

    onMounted(() => {
      modalShowListener(printPreviewRef.value, () => {
        // init();
      });
      modalHideListener(printPreviewRef.value, () => {
        resetForm();
      });
    });

    return {
      t,
      formRef,
      printPreviewRef,
      props,
      loading,
      formData,
      rules,
      options,
      modalShow,
      printProduct,
      handleDiscard,
      resetForm,
      submit,
    };
  },
});
